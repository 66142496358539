/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect, useMemo } from "react";
import { addPayment, getPaymentHistory, getSubscription } from "../../api/payments";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/ru';

dayjs.extend(localizedFormat);

const Subscription = ({ currentLang }) => {
    const { t, i18n } = useTranslation();
    const [subscription, setSubscription] = useState([])
    const [paymentHistory, setPaymentHistory] = useState([])
    const [choosePayment, setChoosePayment] = useState(null)

    dayjs.locale(i18n.language);

    const subscriptionConcat = useMemo(() => {
        if (!subscription) return [];

        const mergedData = subscription.reduce((acc, { amount_of_month, name, type, amount, currency, id }) => {
            acc[amount_of_month] = acc[amount_of_month] || { amount_of_month, name, paymentOptions: [] };
            acc[amount_of_month].paymentOptions.push({ type, amount, currency, id });
            return acc;
        }, {});

        return Object.values(mergedData).map(item => ({
            ...item,
            paymentOptions: item.paymentOptions.sort((a, b) => a.type === "Cryptocloud" ? -1 : 1)
        }));
    }, [subscription]);

    const onSubscription = (paymentId, paymentInfo) => {
        window.dataLayer.push({
            event: 'purchase',
            ecommerce: {
                transaction_id: 'T_12345',
                value: paymentInfo.amount,
                currency: paymentInfo.currency,
                items: [
                    {
                        item_id: paymentId,
                        item_name: choosePayment.name.en
                    }
                ]
            }
        });

        addPayment(paymentId)
    }

    useEffect(() => {
        getSubscription(setSubscription)
        getPaymentHistory(setPaymentHistory)
    }, [])

    return <>
        <section className="subscriptions">
            <div className="container">
                <div className="subscriptions-container__block">
                    <div className="subscriptions__block-info">
                        <h3 className="subscriptions-block-info__title">{t('subscription.info_title')}</h3>

                        <ul className="subscriptions-list-item__list">
                            <li className="subscriptions-list-item__point">
                                {t('subscription.info_item_1')}
                            </li>
                            <li className="subscriptions-list-item__point">
                                {t('subscription.info_item_2')}
                            </li>
                            <li className="subscriptions-list-item__point">
                                {t('subscription.info_item_3')}
                            </li>
                            <li className="subscriptions-list-item__point">
                                {t('subscription.info_item_4')}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="subscriptions-list">
                            {subscriptionConcat.map((item) => (
                                <li key={item.id} className="subscriptions-list__item subscriptions-list-item">
                                    <span
                                        className={`subscriptions-list-item__link subscriptions-list-item__link--active ${item.amount_of_month === choosePayment?.amount_of_month ? 'active' : ''}`}
                                    >
                                        <p className="subscriptions-list-item__period">{item.name[currentLang]} </p>

                                        <p className="subscriptions-list-item__price">
                                            {
                                                item.paymentOptions.map((paymentOption, index, array) => <>
                                                    {paymentOption.amount} {paymentOption.currency}
                                                    {array[++index] ? <><br /><span style={{ fontWeight: "500", fontSize: "20px" }} >{t('subscription.or')}</span><br /></> : ''}</>)
                                            }
                                        </p>

                                        <button type="button" onClick={() => setChoosePayment(item)} className="btn btn--transparent">
                                            {t('subscription.choose')}
                                            {
                                                item.amount_of_month === choosePayment?.amount_of_month && (
                                                    <svg width="21" height="20" viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M10.5 0.25C8.57164 0.25 6.68657 0.821828 5.08319 1.89317C3.47982 2.96451 2.23013 4.48726 1.49218 6.26884C0.754225 8.05042 0.561142 10.0108 0.937348 11.9021C1.31355 13.7934 2.24215 15.5307 3.60571 16.8943C4.96928 18.2579 6.70656 19.1865 8.59787 19.5627C10.4892 19.9389 12.4496 19.7458 14.2312 19.0078C16.0127 18.2699 17.5355 17.0202 18.6068 15.4168C19.6782 13.8134 20.25 11.9284 20.25 10C20.2473 7.41498 19.2192 4.93661 17.3913 3.10872C15.5634 1.28084 13.085 0.25273 10.5 0.25ZM14.7806 8.28063L9.53063 13.5306C9.46097 13.6004 9.37826 13.6557 9.28721 13.6934C9.19616 13.7312 9.09857 13.7506 9 13.7506C8.90144 13.7506 8.80385 13.7312 8.7128 13.6934C8.62175 13.6557 8.53903 13.6004 8.46938 13.5306L6.21938 11.2806C6.07865 11.1399 5.99959 10.949 5.99959 10.75C5.99959 10.551 6.07865 10.3601 6.21938 10.2194C6.36011 10.0786 6.55098 9.99958 6.75 9.99958C6.94903 9.99958 7.1399 10.0786 7.28063 10.2194L9 11.9397L13.7194 7.21937C13.7891 7.14969 13.8718 7.09442 13.9628 7.0567C14.0539 7.01899 14.1515 6.99958 14.25 6.99958C14.3486 6.99958 14.4461 7.01899 14.5372 7.0567C14.6282 7.09442 14.7109 7.14969 14.7806 7.21937C14.8503 7.28906 14.9056 7.37178 14.9433 7.46283C14.981 7.55387 15.0004 7.65145 15.0004 7.75C15.0004 7.84855 14.981 7.94613 14.9433 8.03717C14.9056 8.12822 14.8503 8.21094 14.7806 8.28063Z" fill="#111111" />
                                                    </svg>
                                                )
                                            }
                                        </button>
                                    </span>
                                </li>
                            ))}
                        </ul>

                        {
                            choosePayment && (
                                <div className="subscriptions__choose-payment">
                                    <div className="subscriptions__choose-payment--paid">
                                        {t('subscription.amount_title')}{" "}
                                        <strong>{choosePayment.paymentOptions.map((paymentOption, index, array) =>
                                            `${paymentOption.amount} ${paymentOption.currency}${array[++index] ? ' / ' : ''}`)}</strong>
                                    </div>
                                    <div className="subscriptions-payment__btn">
                                        {
                                            choosePayment.paymentOptions.map(({ id, ...paymentOption }) => (
                                                <button key={id} type="button" onClick={() => onSubscription(id, paymentOption)}
                                                    className="btn btn--transparent subscriptions-list-item__btn"
                                                >
                                                    {paymentOption.type === "Cryptocloud" ? t('subscription.crypto') : t('subscription.card')}
                                                </button>
                                            ))
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <div className="subscriptions-purchase">
                            <span className="subscriptions-purchase__title">{t('subscription.history_title')}</span>

                            <table>
                                {paymentHistory.map((item) => (
                                    <tr key={item.created_at} className="subscriptions-purchase__list">
                                        <td className="subscriptions-purchase__list-item">
                                            {dayjs(item.created_at).format('MMMM DD, YYYY')}
                                        </td>
                                        <td className="subscriptions-purchase__list-item">
                                            {t('subscription.subscription_for', { count: item.payment.amount_of_month })}
                                        </td>
                                        <td className="subscriptions-purchase__list-item">
                                            {t("subscription.till")} {" "}
                                            {dayjs(item.subscription_expires_at).format('MMMM DD, YYYY')}
                                        </td>
                                        <td className="subscriptions-purchase__list-item">
                                            {item.payment.amount} {item.payment.currency}
                                        </td>
                                        <td className="subscriptions-purchase__list-item" align="right">
                                            {item.payment.type.toLowerCase()?.includes('crypto') ? t('subscription.crypto') : item.payment.type}
                                        </td>
                                    </tr>
                                ))}
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>

}

export default Subscription
